/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react"
import Compressor from "compressorjs"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  Container,
  FormGroup,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { useLocation, useNavigate } from "react-router-dom"
import uploadImage from "../../../assets/images/uploadImage.png"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { routes } from "routes/path"
import ImageCrop from "./ImageCrop"

function UpdateProduct() {
  const { putApi, getApi, postApi } = useApi()

  document.title = `Update Product | ${localStorage.getItem("ShopName")}`

  const { state } = useLocation()
  const navigate = useNavigate()
  let { pathname } = useLocation()
  // useStates
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [previewUrl, setPreviewUrl] = useState(state?.data?.Image || '')
  const [isEdit, setIsEdit] = useState(state ? true : false)
  const [productEditData, setProductEditData] = useState(state?.data)
  const [productId, setProductId] = useState(state?.productId)
  const [categories, setCategories] = useState([])
  const [colors, setColors] = useState([])
  const [size, setSize] = useState([])
  const [fileFlag, setfileFlag] = useState(false)
  const [imageFlag, setImageFlag] = useState(false)
  const [screeLoad, setscreeLoad] = useState(state ? false : true)
  const [isInStock, setIsInStock] = useState(state?.data?.IsInStock || false)
  const [cropImage, setCropImage] = useState(state?.data?.Image || '')
  const [modal1, setModal1] = useState(false)
  const [enableProductStock, setEnableProductStock] = useState(false)

  // product detail api useEffect
  useEffect(() => {
    getApi("Category/category-lookup").then(({ data, status }) => {
      if (status === 200) {
        setCategories(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

    getApi("color/color-lookup").then(({ status, data }) => {
      if (status === 200) {
        setColors(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

    getApi("size/size-lookup").then(({ data, status }) => {
      if (status === 200) {
        setSize(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

    postApi("Setting/setting-by-keys", {
      key: ["ENABLE_PRODUCT_STOCK"],
      userId: JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id,
    }).then(({ data, status }) => {
      if (status == 200) {
        setEnableProductStock(data[0].Value == "1")
      }
    })
  }, [])

  const toggle1 = () => {
    setModal1(!modal1)
  }

  // update product validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      Color: isEdit ? productEditData?.ColorId : "",
      Code: isEdit ? productEditData?.Code : "",
      Name: isEdit ? productEditData?.Name : "",
      Size: isEdit ? productEditData?.SizeId : 0,
      remark: isEdit ? productEditData?.Remarks : "",
      Category: isEdit ? productEditData?.CategoryId : "",
      Rent: isEdit ? productEditData?.Rent : 0,
      MRP: isEdit ? productEditData?.MRP : 0,
      productQty: isEdit ? productEditData?.Qty : 1,
      HSN: isEdit ? productEditData?.HSN : 0,
      profilePic: isEdit ? productEditData?.Image : null,
    },
    validationSchema: Yup.object({
      Code: Yup.string().required("The Product Code is required"),
      Name: Yup.string().required("The Product Name is required"),
      Category: Yup.number().min(1, "The Category is required"),
      Rent: Yup.number().min(0, "The Rent is required").required("The Rent is required"),
    }),
    onSubmit: values => {
      if (isEdit) {
        setformSumitLoading(true)
        putApi("Product/update", {
          id: productId,
          sizeId: values.Size == 0 ? null : values.Size,
          colorId: values.Color == 0 ? null : values.Color,
          name: values.Name,
          rent: parseInt(values.Rent),
          MRP: parseInt(values.MRP || 0),
          HSN: values.HSN,
          image: imageFlag ? cropImage.split(",")[1] : "",
          deleteImage: fileFlag,
          remarks: values.remark ? values.remark : "",
          isInStock: isInStock,
          qty: values.productQty,
        }).then(({ data, status }) => {
          setformSumitLoading(false)
          if (status == 200) {
            setImageFlag(false)
            setfileFlag(false)
            toast.success("Product update successfully", {
              style: {
                fontSize: "15px",
              },
            })
            navigate(routes.productList)
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
            setCropImage('')
            validation.handleReset()
            validation.setFieldValue("profilePic", "")
          }
        })
      }
    },
  })

  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  // image select handler & Compressor
  const handleDrop = acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    setImageFlag(true)
    new Compressor(selectedFile, {
      quality: 0.4,
      success: async compressedResult => {
        setPreviewUrl(URL.createObjectURL(compressedResult))
        toggle1()
      },
    })
  }

  // image handler
  const handleclick = () => {
    setfileFlag(true)
    validation.setFieldValue("profilePic", null)
    setPreviewUrl('')
    setCropImage('')
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          < Breadcrumbs
            parent={breadcrumbs[pathname].parent}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
            parentLink={breadcrumbs[pathname].parentLink}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault()
                      validation.handleReset()
                      validation.setFieldValue("profilePic", '')
                      return false
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <Dropzone
                            onDrop={handleDrop}
                            onClick={handleDrop}
                            accept="image/*"
                            multiple={false}
                            id="profilePic"
                            name="profilePic"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <>
                                <div className="d-flex flex-column align-items-center text-center p-2 py-3">
                                  {cropImage ? (
                                    <>
                                      <div className="position-relative overflow-hidden product-image">
                                        <input {...getInputProps()} />
                                        <img
                                          style={{
                                            cursor: "pointer",
                                            border: "2px solid",
                                          }}
                                          width="175px"
                                          height="200px"
                                          src={cropImage}
                                          {...getRootProps()}
                                        />
                                        <div className="delete-button">
                                          <button
                                            className="btn btn-sm btn-danger mb-1 me-1"
                                            onClick={() => handleclick()}
                                            type="button"
                                          >
                                            <i className=" fs-6 fas fa-trash-alt "></i>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <input {...getInputProps()} />

                                      <img
                                        className=""
                                        style={{
                                          cursor: "pointer",
                                          border: "1px solid #ced4da",
                                        }}
                                        width="175px"
                                        height="200px"
                                        src={uploadImage}
                                        {...getRootProps()}
                                      />
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </Dropzone>
                          {validation.touched.profilePic &&
                            validation.errors.profilePic ? (
                            <div className="error">
                              {validation.errors.profilePic}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="9">
                        <Row>
                          <Col md="3">
                            <div className="mb-2">
                              <Label
                                htmlFor="formrow-InputState"
                                className="required"
                              >
                                Category
                              </Label>
                              <Input
                                type="select"
                                bsSize="sm"
                                disabled
                                id="formrow-InputState"
                                className="form-control-sm"
                                name="Category"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Category || ""}
                                invalid={
                                  validation.touched.Category &&
                                    validation.errors.Category
                                    ? true
                                    : false
                                }
                              >
                                <option>Select</option>
                                {categories.map((item, index) => {
                                  return (
                                    <option key={index} value={item.Key}>
                                      {item.Value}
                                    </option>
                                  )
                                })}
                              </Input>
                            </div>
                            {validation.touched.Category &&
                              validation.errors.Category ? (
                              <FormFeedback type="invalid">
                                {validation.errors.Category}
                              </FormFeedback>
                            ) : null}
                          </Col>
                          <Col md="5">
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom03"
                                className="required"
                              >
                                Product Name
                              </Label>
                              <Input
                                name="Name"
                                placeholder="Product Name"
                                type="text"
                                className="form-control-sm"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Name || ""}
                                invalid={
                                  validation.touched.Name &&
                                    validation.errors.Name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Name &&
                                validation.errors.Name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Name}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb-2">
                              <Label htmlFor="validationCustom01">Color</Label>
                              <Input
                                type="select"
                                bsSize="sm"
                                id="validationCustom01"
                                className="form-control-sm"
                                name="Color"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Color || ""}
                                invalid={
                                  validation.touched.Color &&
                                    validation.errors.Color
                                    ? true
                                    : false
                                }
                              >
                                <option value={0}>Select</option>
                                {colors.map((item, index) => {
                                  return (
                                    <option key={index} value={item.Key}>
                                      {item.Value}
                                    </option>
                                  )
                                })}
                              </Input>
                              {validation.touched.Color &&
                                validation.errors.Color ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Color}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <div className="mb-2">
                              <FormGroup className="mb-2">
                                <Label htmlFor="validationCustom02">Size</Label>
                                <Input
                                  type="select"
                                  id="validationCustom02"
                                  bsSize="sm"
                                  className="form-control-sm"
                                  name="Size"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.Size || ""}
                                  invalid={
                                    validation.touched.Size &&
                                      validation.errors.Size
                                      ? true
                                      : false
                                  }
                                >
                                  <option value={0}>Select</option>
                                  {size.map((item, index) => {
                                    return (
                                      <option key={index} value={item.Key}>
                                        {item.Value}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {validation.touched.Size &&
                                  validation.errors.Size ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.Size}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                            {validation.touched.Size &&
                              validation.errors.Size ? (
                              <FormFeedback type="invalid">
                                {validation.errors.Size}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="2">
                            <div className="mb-2">
                              <Label className="form-label required">
                                Rent
                              </Label>
                              <Input
                                name="Rent"
                                className="form-control-sm"
                                placeholder="Rent"
                                type="Rent"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Rent}
                                invalid={
                                  validation.touched.Rent &&
                                    validation.errors.Rent
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Rent &&
                                validation.errors.Rent ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Rent}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="mb-2">
                              <Label className="form-label">
                                MRP
                              </Label>
                              <Input
                                name="MRP"
                                className="form-control-sm"
                                placeholder="0"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                onKeyPress={handleKeyPress}
                                value={validation.values.MRP}
                                invalid={
                                  validation.touched.MRP &&
                                    validation.errors.MRP
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.MRP &&
                                validation.errors.MRP ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.MRP}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom02"
                                className="required"
                              >
                                Code
                              </Label>
                              <Input
                                name="Code"
                                placeholder="Code"
                                type="text"
                                disabled
                                className="form-control-sm"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.Code || ""}
                                invalid={
                                  validation.touched.Code &&
                                    validation.errors.Code
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.Code &&
                                validation.errors.Code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.Code}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom02"
                                className=""
                              >
                                HSN Code
                              </Label>
                              <Input
                                name="HSN"
                                placeholder="HSN Code"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.HSN || ""}
                                invalid={
                                  validation.touched.HSN &&
                                    validation.errors.HSN
                                    ? true
                                    : false
                                }
                              />
                            </FormGroup>
                          </Col>
                          {enableProductStock &&
                            <Col md="2">
                              <div className="mb-2">
                                <Label className="form-label">
                                  Product Qty
                                </Label>
                                <Input
                                  name="productQty"
                                  className="form-control-sm"
                                  placeholder="0"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  onKeyPress={handleKeyPress}
                                  value={validation.values.productQty}
                                  invalid={
                                    validation.touched.productQty &&
                                      validation.errors.productQty
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.productQty &&
                                  validation.errors.productQty ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.productQty}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>}
                          <Col md="2">
                            <div className="d-flex justify-content-start align-items-center gap-2 mt-3">
                              <>
                                <input
                                  id="checkbox2"
                                  type="checkbox"
                                  className=""
                                  style={{ cursor: "pointer" }}
                                  defaultChecked={isInStock}
                                  onChange={e => setIsInStock(e.target.checked)}
                                />
                                <label style={{ cursor: "pointer" }} htmlFor="checkbox2" className="pt-2"  >
                                  In Stock
                                </label></> </div>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-2">
                              <Label htmlFor="validationCustom05">
                                Remarks
                              </Label>
                              <Input
                                name="remark"
                                rows="2"
                                placeholder="Type your remark here"
                                type="textarea"
                                className="form-control-sm"
                                value={validation.values.remark || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="text-end">
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2"
                          type="button"
                          onClick={() => {
                            navigate(routes.productList)
                          }}
                        >
                          Cancle
                        </Button>
                        {formSumitLoading ? (
                          <button type="button" className="btn btn-primary ">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                            Loading
                          </button>
                        ) : (
                          <Button
                            color="primary"
                            className="btn btn-primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>

                  <Modal
                    isOpen={modal1}
                    toggle={toggle1}
                    centered={true}
                    size="lg"
                    style={{ width: "580px" }}
                  >
                    <div className="modal-content">
                      <ModalHeader toggle={toggle1} tag="h5">
                        {"Crop Image"}
                      </ModalHeader>
                      <ModalBody style={{ height: "490px" }}>
                        <ImageCrop
                          image={previewUrl}
                          setCropImage={setCropImage}
                          toggle={toggle1}
                          validation={validation}
                        />
                      </ModalBody>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdateProduct
