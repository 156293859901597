import React, { useEffect, useMemo, useState } from "react"
import { Card, CardBody, Container, FormGroup, Input, Label } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import breadcrumbs from "common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import ProductHistoryTable from "./ProductHistoryTable"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import { toast } from "react-hot-toast"
import uploadImage from "../../../assets/images/uploadImage.png"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"

function ProductHistory() {
  document.title = `Product History | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()
  const { postApi } = useApi()
  const [tableData, settableData] = useState([])
  const [IsLoading, setIsLoading] = useState(false)
  const [searchText, setsearchText] = useState("")
  const [pendingForReturn, setpendingForReturn] = useState(false)
  const [totalBooking, setTotalBooking] = useState(0)
  const [viewtotaliBooking, setViewTotalBooking] = useState(false)
  const [productDetails, setProductDetails] = useState()
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [modal1, setModal1] = useState(false)
  const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", productId: "" })

  // handlers
  const toggle1 = () => {
    setModal1(!modal1)
  }

  // view booking
  const handleViewBooking = bookingId => {
    setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
    toggle1()
  }


  const columns = useMemo(
    () => [
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "4%",
        Cell: ({ row }) => {
          return <>
            {row.original.TotalProduct == 0 ? (
              <div style={{ color: "#E94560" }} >{row.original.BillNo}</div>
            ) : (
              <div
                style={{ cursor: "pointer", color: "#556ee6" }}
                onClick={() => {
                  handleViewBooking(row.original.Id)
                }}
              >
                {row.original.BillNo}
              </div>
            )}
          </>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Code
          </div>
        ),
        accessor: "ProductCode",
        width: "5%",
        Cell: ({ row }) => {
          return <div>{productDetails?.ProductCode}</div>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Booking Date
          </div>
        ),
        accessor: "BookingDate",
        width: "7%",
        Cell: ({ row }) => {
          return <div>{dateFormat(row.original.BookingDate, "dd/MM/yyyy")}</div>
        },
      },
      {
        Header: "Status",
        accessor: "Status",
        width: "7%",
        Cell: ({ row }) => {
          return <div>{row.original.Status == 1 ? "Booked" : row.original.Status == 2 ? "Delivered" : row.original.Status == 3 ? "Return" : "Cancel"}</div>
        },
      },
      {
        Header: "Delivery",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Rent
          </div>
        ),
        accessor: "Amount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Amount || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Qty
          </div>
        ),
        accessor: "Qty",
        width: "4%",
        Cell: ({ row }) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              {row?.original?.Qty || 1}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Customer Name
          </div>
        ),
        accessor: "CustomerName",
        Cell: ({ row }) => {
          return <div>{row.original.CustomerName}</div>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Contact No.
          </div>
        ),
        width: "7%",
        accessor: "CustomerContactNo",
        Cell: ({ row }) => {
          return <div>{row.original.CustomerContactNo}</div>
        },
      },
    ],
    [tableData]
  )

  const handleSearch = () => {
    setIsLoading(true)
    if (searchText) {
      postApi("Report/product-history", {
        productCode: searchText.trim(),
        pendingForReturn,
      }).then(({ data, status }) => {
        if (status === 200) {
          settableData(data.ProductHistoryList)
          setProductDetails(data)
          setViewTotalBooking(true)
          setIsLoading(false)
          setTotalBooking(data.ProductHistoryList.length)
        } else {
          setViewTotalBooking(false)
          setIsLoading(false)
          settableData([])
          setProductDetails()
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    } else {
      setIsLoading(false)
      setViewTotalBooking(false)
      settableData([])
      setProductDetails()
      toast.error("search text is required", {
        style: {
          fontSize: "15px",
        },
      })
    }
  }

  useEffect(() => {
    if (searchText) {
      handleSearch()
    }
  }, [pendingForReturn])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={breadcrumbs[pathname].title}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          />
          <Card>
            <CardBody>
              <div className="row mb-3">
                <div className="col-md-3">
                  <input
                    onChange={e => {
                      setsearchText(e.target.value)
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleSearch()
                      }
                    }}
                    id="search-bar-0"
                    type="text"
                    className="form-control"
                    placeholder={"Search Product Code..."}
                    value={searchText}
                  />
                </div>

                <div className="col-md-4 px-0">
                  <div className="row">
                    <div className={`${isMobile ? 'serchButtonStyle' : ''} col-md-6`} style={{ paddingRight: "0px" }}>
                      {IsLoading ? (
                        <button type="button" className="btn btn-primary">
                          <i className="bx bx-hourglass bx-spin font-size-13 align-middle me-1"></i>
                          Loading
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={() => {
                            handleSearch()
                          }}
                        >
                          <i className="bx bx-search-alt font-size-16 align-middle me-2"></i>
                          Search
                        </button>
                      )}
                    </div>
                    <div
                      className={`${isMobile ? 'pendingReturnStyle' : ''} col-md-5 px-0 d-flex align-items-center text-nowrap`}
                      style={{ paddingLeft: "0px" }}
                    >
                      <FormGroup check>
                        <Input
                          id="checkbox2"
                          type="checkbox"
                          checked={pendingForReturn}
                          onChange={e => setpendingForReturn(e.target.checked)}
                        />
                        <Label for="checkbox2" check>
                          Return Pending
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                </div>

                {viewtotaliBooking ? (
                  <div
                    className={`${isMobile ? 'totalBoking' : ''} col-md-5 d-flex align-items-center justify-content-end text-end`}
                    style={{ color: "#0F6E25", fontSize: "15px" }}
                  >
                    Total {totalBooking} Bookings for
                    {` ${productDetails?.ProductName}`}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row">
                <div className="col-md-10">
                  <ProductHistoryTable
                    columns={columns}
                    data={tableData}
                    isGlobalFilter={false}
                    isCreateBooking={false}
                    customPageSize={1000}
                    IsLoading={IsLoading}
                    className="custom-header-css"
                  />
                </div>
                <div className="col-md-2 ">
                  <div>
                    <div>
                      <div
                        style={{
                          width: "150px",
                          height: "170px",
                          border: "1px solid #ced4da",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                        }}
                      >
                        <img
                          className="img-fluid"
                          style={{
                            width: "140px",
                            height: "160px",
                          }}
                          src={
                            productDetails?.ProductImage
                              ? productDetails?.ProductImage
                              : uploadImage
                          }
                        />
                      </div>
                      <div>
                        {
                          (productDetails?.IsSale == true) && (
                            <div className="mt-2 text-center " style={{ fontSize: "14px" }}>
                              <span style={{ fontWeight: "600", color: "#dd3939" }}>Sale</span>
                            </div>
                          )
                        }
                        {
                          (productDetails?.IsRetire == true && productDetails?.IsSale == false) && (
                            <div className="mt-2 text-center " style={{ fontSize: "14px" }}>
                              <span style={{ fontWeight: "600", color: "#dd3939" }}>Retire</span>
                            </div>
                          )
                        }
                      </div>
                    </div>
                    <div>
                      <div className="mt-1 " style={{ fontSize: "15px" }}>
                        <div>
                          <span style={{ fontWeight: "600" }}> Name:</span> {productDetails?.ProductName ? productDetails?.ProductName : "N/A"}
                        </div>

                        <div className="mt-1">
                          <span style={{ fontWeight: "600" }}> Code:</span> {productDetails?.ProductCode ? productDetails?.ProductCode : "N/A"}
                        </div>
                        <div className="mt-1">
                          <span style={{ fontWeight: "600" }}> MRP: </span>
                          <i className="mdi mdi-currency-inr"></i>
                          {new Intl.NumberFormat('en-IN', {
                            currency: 'INR',
                            minimumFractionDigits: 2,
                          }).format(productDetails?.MRP
                            || 0)}
                        </div>
                        <div className="mt-1">
                          <span style={{ fontWeight: "600" }}>Color:</span> {productDetails?.ColorName ? productDetails?.ColorName : "N/A"}
                        </div>

                        <div className="mt-1" style={{ display: "flex", justifyContent: "space-between" }}>
                          <span><span style={{ fontWeight: "600" }}>Size: </span> {productDetails?.SizeName ? productDetails?.SizeName : "N/A"}</span>
                          <span>
                            <span style={{ fontWeight: "600" }}> Qty: </span>
                            {productDetails?.Qty || "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <InvoiceDetalis
                toggle={toggle1}
                modal={modal1}
                bookingId={BookingIdandStatusId.bookingId}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProductHistory
