import useApi from 'common/ApiMiddlewere'
import { dateFormat } from 'pages/Common'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Card, CardBody, Col, Row, Table } from "reactstrap"
import { map } from "lodash"
import noImage from "../../assets/images/no_image.jpg"

const SaleInvoiceDetail = ({ toggle, modal, saleId }) => {
    const { getApi } = useApi()
    const [invoiceDetails, setInvoiceDetails] = useState()
    const [invoiceTable, setinvoiceTable] = useState()
    const [imageModal, setImageModal] = useState(false)
    const [clickImageURL, setClickImageURL] = useState('')
    // let CGST = parseFloat(localStorage.getItem("GST").split("|")[0] || 0)
    // let SGST = parseFloat(localStorage.getItem("GST").split("|")[1] || 0)

    useEffect(() => {
        if (saleId && modal) {
            getApi(`Sales/details?id=${String(saleId)}`).then(
                ({ data, status }) => {
                    if (status === 200) {
                        setInvoiceDetails(data.SaleDetailModel)
                        setinvoiceTable(data.SaleProductList)
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                }
            )
        }
    }, [saleId, modal])

    const imageToggle = () => {
        setImageModal(!imageModal)
    }

    const handleClose = () => {
        setImageModal(!imageModal)
    }

    const handleImageClick = (Image) => {
        setClickImageURL(Image)
        setImageModal(true)
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} centered={true} size="xl">
                <div className="modal-content">
                    <ModalHeader className="text-centre" toggle={toggle} tag="h5">
                        <span style={{ fontWeight: "600", fontSize: "20px", marginLeft: "8px" }}> Sale Details </span>
                    </ModalHeader>
                    <ModalBody>
                        <React.Fragment>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody className="p-1">
                                            <Row>
                                                <Col sm="5" className="text-sm-start">
                                                    <address style={{ marginBottom: "4px" }}> <span style={{ fontSize: "16px", fontWeight: "600" }}>
                                                        Customer Details</span>
                                                        <br />
                                                        <span style={{ fontSize: "15px", fontWeight: "600" }}>
                                                            Name: </span>
                                                        <span style={{ fontSize: "14px" }}>{invoiceDetails?.Name}</span>
                                                        <br />
                                                        <span style={{ fontSize: "15px", fontWeight: "600" }}>
                                                            Contact: </span>
                                                        <span style={{ fontSize: "14px" }}>
                                                            {invoiceDetails?.ContactNo}
                                                        </span>
                                                    </address>
                                                    <div>
                                                        <address> <span style={{ fontSize: "15px", fontWeight: "600" }}>
                                                            Address: </span>
                                                            <span style={{ fontSize: "14px" }}>{invoiceDetails?.Address}</span>
                                                            <br />
                                                        </address>
                                                    </div>
                                                </Col>
                                                <Col sm="7" className="text-end">
                                                    <h4 style={{ marginBottom: "5px" }}>
                                                        <span style={{ fontSize: "16px", fontWeight: "600" }}> Bill No:</span> <span style={{ fontSize: "14px" }}> {invoiceDetails?.BillNo}</span>
                                                    </h4>
                                                    <address className="mb-0">
                                                        <span style={{ fontSize: "16px", fontWeight: "600" }}>
                                                            Date: </span>
                                                        <span style={{ fontSize: "14px" }}>
                                                            {
                                                                dateFormat(
                                                                    invoiceDetails?.CreatedOn.split("T")[0],
                                                                    "dd/MM/yyyy"
                                                                )
                                                            }</span>
                                                        <br />
                                                        <br />
                                                    </address>
                                                </Col>
                                            </Row>
                                            <Row className="py-2 mt-1">
                                                <Col sm={4}><div >
                                                    <h3 className="font-size-16" style={{ fontWeight: "600" }}>Product Detail</h3>
                                                </div></Col>
                                            </Row>
                                            <div className="table-responsive">
                                                <Table className="table-nowrap" bordered>
                                                    <thead>
                                                        <tr>
                                                            <th className="custom-header-invoice" style={{ width: "20px" }}>No.</th>
                                                            <th className="custom-header-invoice" style={{ width: "30px" }}>Code</th>
                                                            <th className="custom-header-invoice" >Name</th>
                                                            <th className="custom-header-invoice" style={{ width: "20px" }}>
                                                                Qty
                                                            </th>
                                                            <th className="custom-header-invoice text-end" style={{ width: "60px" }}>
                                                                Price
                                                            </th>
                                                            <th className="custom-header-invoice text-end" style={{ width: "60px" }}>
                                                                Taxable Amt.
                                                            </th>
                                                            <th className="custom-header-invoice text-end" style={{ width: "60px" }}>
                                                                CGST
                                                            </th>
                                                            <th className="custom-header-invoice text-end" style={{ width: "60px" }}>
                                                                SGST
                                                            </th>
                                                            <th className="custom-header-invoice text-end" style={{ width: "60px" }}>
                                                                IGST
                                                            </th>
                                                            <th className="custom-header-invoice text-end" style={{ width: "60px" }}>
                                                                Net Price
                                                            </th>
                                                            <th className="custom-header-invoice text-end" style={{ width: "60px" }}>
                                                                Total Amt.
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {map(invoiceTable, (item, key) => (
                                                            <tr key={key}>
                                                                <td className='custom-header1'>{key + 1}</td>
                                                                <td className='custom-header1'>{item.ProductCode}</td>
                                                                <td className='text-wrap custom-header1' >{item.ProductName}</td>
                                                                <td className='custom-header1' style={{ width: "20px" }}>
                                                                    {item?.Qty}
                                                                </td>
                                                                <td className="text-sm-end custom-header1">
                                                                    <i className="mdi mdi-currency-inr"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format((item?.Price).toFixed(2) || 0)}
                                                                </td>
                                                                <td className="text-sm-end custom-header1">
                                                                    <i className="mdi mdi-currency-inr"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format((item?.Price * item?.Qty).toFixed(2) || 0)}
                                                                </td>
                                                                <td className="text-sm-end custom-header1">
                                                                    {item?.CGSTPer ? item?.CGSTPer : "0"}% |  <i className="mdi mdi-currency-inr"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format((item?.CGSTAmount).toFixed(2) || 0)}
                                                                </td>
                                                                <td className="text-sm-end custom-header1">
                                                                    {item?.SGSTPer ? item?.SGSTPer : "0"}% |  <i className="mdi mdi-currency-inr"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format((item?.SGSTAmount).toFixed(2) || 0)}
                                                                </td>
                                                                <td className="text-sm-end custom-header1">
                                                                    {item?.IGSTPer ? item?.IGSTPer : "0"}% |   <i className="mdi mdi-currency-inr"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format((item?.IGSTAmount).toFixed(2) || 0)}
                                                                </td>
                                                                <td className="text-sm-end custom-header1">
                                                                    <i className="mdi mdi-currency-inr"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format((item?.TotalPrice).toFixed(2) || 0)}
                                                                </td>
                                                                <td className="text-sm-end custom-header1">
                                                                    <i className="mdi mdi-currency-inr"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(item?.TotalAmount || 0)}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td rowSpan={4}
                                                                colSpan={7}
                                                                className="custom-header-invoice"
                                                            >
                                                                <span style={{ fontSize: "14px", fontWeight: "600" }}>Remarks :</span>
                                                                <br />
                                                                <div className='text-wrap'
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: invoiceDetails?.Remarks
                                                                        ,
                                                                    }}>
                                                                </div>
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                CGST Amt.
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                <i className="mdi mdi-currency-inr"></i>
                                                                {new Intl.NumberFormat('en-IN', {
                                                                    currency: 'INR',
                                                                    minimumFractionDigits: 2,
                                                                }).format(invoiceDetails?.CGSTAmount || 0)}
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                Taxable Amt.
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                <i className="mdi mdi-currency-inr"></i>
                                                                {new Intl.NumberFormat('en-IN', {
                                                                    currency: 'INR',
                                                                    minimumFractionDigits: 2,
                                                                }).format(invoiceDetails?.TaxableAmount || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                SGST Amt.
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                <i className="mdi mdi-currency-inr"></i>
                                                                {new Intl.NumberFormat('en-IN', {
                                                                    currency: 'INR',
                                                                    minimumFractionDigits: 2,
                                                                }).format(invoiceDetails?.SGSTAmount || 0)}
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                Taxes(+)
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                <i className="mdi mdi-currency-inr"></i>
                                                                {new Intl.NumberFormat('en-IN', {
                                                                    currency: 'INR',
                                                                    minimumFractionDigits: 2,
                                                                }).format(invoiceDetails?.CGSTAmount + invoiceDetails?.SGSTAmount + invoiceDetails?.IGSTAmount || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                IGST Amt.
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                <i className="mdi mdi-currency-inr"></i>
                                                                {new Intl.NumberFormat('en-IN', {
                                                                    currency: 'INR',
                                                                    minimumFractionDigits: 2,
                                                                }).format(invoiceDetails?.IGSTAmount || 0)}
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                Discount(-)
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                <i className="mdi mdi-currency-inr"></i>
                                                                {new Intl.NumberFormat('en-IN', {
                                                                    currency: 'INR',
                                                                    minimumFractionDigits: 2,
                                                                }).format(invoiceDetails?.Discount || 0)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">Total Qty</td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">{invoiceDetails?.TotalQty}</td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                Net Amt.
                                                            </td>
                                                            <td style={{ fontWeight: "600" }} className="border-0 text-end custom-header-invoice">
                                                                <i className="mdi mdi-currency-inr"></i>
                                                                {new Intl.NumberFormat('en-IN', {
                                                                    currency: 'INR',
                                                                    minimumFractionDigits: 2,
                                                                }).format(Math.round(invoiceDetails?.NetAmount)) || 0}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment >
                    </ModalBody>
                </div>
            </Modal >

            <Modal isOpen={imageModal} toggle={imageToggle} centered={true} size="small"
                style={{ width: "350px", background: "transparent" }} >
                <ModalBody style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "transparent" }}>
                    <React.Fragment>
                        <Row>
                            <div
                                style={{
                                    // width: "270px",
                                    // height: "270px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    style={{ width: "230px" }}
                                    className="img-fluid"
                                    src={clickImageURL}
                                />
                            </div>
                            <div
                                style={{
                                    top: "2px",
                                    cursor: "pointer",
                                    left: "310px",
                                    position: "absolute",
                                }}
                            >
                                <span onClick={handleClose}> <i className='fs-2 bx bx-x'></i></span>
                            </div>
                        </Row>
                    </React.Fragment >
                </ModalBody>
            </Modal>

        </div >
    )
}

export default SaleInvoiceDetail