import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import SalesmanTable from "./SalesmanTable"

function Salesman() {
  let { pathname } = useLocation()
  const { getApi, postApi } = useApi()

  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectMonth, setSelectMonth] = useState()
  const [salesmanSelectId, setSalesmanSelectId] = useState(0)
  const [salemanList, setSalesmanList] = useState([])
  const [transactionType, setTransactionType] = useState(1)

  // formDate & toDate
  const dateValue = (month, saleamanId, transactionType) => {
    setSalesmanSelectId(saleamanId)
    setSelectMonth(month)
    setTransactionType(transactionType)
  }

  // return api useEffect
  useEffect(() => {
    getApi("User/vendor-salesman-lookup").then(({ data, status }) => {
      if (status === 200) {
        setSalesmanList(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }, [])

  useEffect(() => {
    saleamanReportApi()
  }, [selectMonth, salesmanSelectId, transactionType])

  // returnList api
  const saleamanReportApi = () => {
    if (salesmanSelectId > 0) {
      setIsLoading(true)
      postApi("Report/salesman-report", {
        salesmanId: parseInt(salesmanSelectId),
        month: selectMonth
          ? selectMonth
          : new Date().toISOString().slice(0, 10),
        transactionType: parseInt(transactionType),
      }).then(({ data, status }) => {
        if (status === 200) {
          setIsLoading(false)
          let NoOfBill = 0
          let NoOfProduct = 0
          let TotalAmount = 0
          data.map((item, i) => {
            NoOfBill += parseInt(item.NoOfBill)
            NoOfProduct += parseInt(item.NoOfProduct)
            TotalAmount += parseInt(item.Amount)
          })
          let obj = {
            SalesmanName: "Total",
            NoOfBill: NoOfBill,
            NoOfProduct: NoOfProduct,
            BillDate: "",
            Amount: TotalAmount,
          }

          if (data.length) {
            data.push(obj)
          }

          setTableData(data)
        } else {
          setIsLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    } else {
      setTableData([])
    }
  }

  // header and accessor
  const columns = useMemo(
    () => [
      {
        Header: "Salesman Name",
        accessor: "SalesmanName",
        Cell: ({ row }) => (
          <div style={{ fontWeight: row.original.SalesmanName == "Total" ? "bold" : "", fontSize: row.original.SalesmanName == "Total" ? "15px" : "", textAlign: row.original.SalesmanName == "Total" ? "end" : "" }}>{row.original.SalesmanName}</div>
        ),
      },
      {
        Header: "Bill Date",
        accessor: "BillDate",
        width: "7%",
        Cell: ({ row }) => (
          <p className="mb-0">
            {row.original.BillDate == "0001-01-01T00:00:00"
              ? "N/A"
              : row.original.BillDate == "" ? "" : dateFormat(row.original.BillDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "No. of Bill",
        accessor: "NoOfBill",
        width: "5%",
        Cell: ({ row }) => (
          <div className="text-center" style={{ fontWeight: row.original.SalesmanName == "Total" ? "bold" : "", fontSize: row.original.SalesmanName == "Total" ? "15px" : "" }}>{row.original.NoOfBill}</div>
        ),
      },
      {
        Header: "No. of Product",
        accessor: "NoOfProduct",
        width: "5%",
        Cell: ({ row }) => (
          <div className="text-center" style={{ fontWeight: row.original.SalesmanName == "Total" ? "bold" : "", fontSize: row.original.SalesmanName == "Total" ? "15px" : "" }}>{row.original.NoOfProduct}</div>
        ),
      },

      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Amount
          </div>
        ),
        accessor: "Amount",
        width: "7%",
        textAlign: "right",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end",
              fontWeight: row.original.SalesmanName == "Total" ? "bold" : "", fontSize: row.original.SalesmanName == "Total" ? "15px" : ""
            }}>
              {row?.original?.Amount &&
                <>
                  <i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(row?.original?.Amount || 0)}
                </>
              }
            </div>
          )
        },
      },
    ],
    [tableData]
  )

  document.title = `Salesman | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <SalesmanTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              dateValue={dateValue}
              isLoading={isLoading}
              salemanList={salemanList}
              className="custom-header-css"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Salesman.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Salesman
