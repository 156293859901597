


export const Booking_status = [{
    Key: "Booked",
    Value: 1,
    DisplayName: "Booked",
},
{
    Key: "Delivered",
    Value: 2,
    DisplayName: "Delivered",
},
{
    Key: "Return",
    Value: 3,
    DisplayName: "Return",
},
{
    Key: "Cancel",
    Value: 4,
    DisplayName: "Cancel",
},
{
    Key: "Hold",
    Value: 5,
    DisplayName: "Hold",
},
]

export const Booking_Item_status = [{
    Key: "Booked",
    Value: 1,
    DisplayName: "Booked",
},
{
    Key: "Delivered",
    Value: 2,
    DisplayName: "Delivered",
},
{
    Key: "Return",
    Value: 3,
    DisplayName: "Return",
},
{
    Key: "Cancel",
    Value: 4,
    DisplayName: "Cancel",
},
]

export const Booking_status1 = [{
    Key: "Booked",
    Value: 1,
    DisplayName: "Booked",
},
{
    Key: "Delivered",
    Value: 2,
    DisplayName: "Delivered",
},
{
    Key: "Return",
    Value: 3,
    DisplayName: "Return",
},
]

export const Verification_Status = [{
    Key: "Approved",
    Value: 1,
    DisplayName: "Approved",
},
{
    Key: "InReview",
    Value: 2,
    DisplayName: "In Review",
},
{
    Key: "Rejected",
    Value: 3,
    DisplayName: "Rejected",
},
]

export function GetObjByVerificationStatus(...values) {
    const array = [
    {
        Key: "InReview",
        Value: 1,
        DisplayName: "In Review",
    },
    {
        Key: "Approved",
        Value: 2,
        DisplayName: "Approved",
    },
    {
        Key: "Rejected",
        Value: 3,
        DisplayName: "Rejected",
    },
    ]
    return array.filter(obj => values.includes(obj.Value));
}


export function GetObjByTargetGender(...values) {
    const array = [
        {
            Key: "Male",
            Value: 1,
            DisplayName: "Male",
        },
        {
            Key: "Female",
            Value: 2,
            DisplayName: "Female",
        },
        {
            Key: "Unisex",
            Value: 3,
            DisplayName: "Unisex",
        },
    ]
    return array.filter(obj => values.includes(obj.Value));
}


function GetObjectsByValues(...values) {
    const array = [
        {
            Key: "Booked",
            Value: 1,
            DisplayName: "Booked",
        },
        {
            Key: "Delivered",
            Value: 2,
            DisplayName: "Delivered",
        },
        {
            Key: "Return",
            Value: 3,
            DisplayName: "Return",
        },
        {
            Key: "Cancel",
            Value: 4,
            DisplayName: "Cancel",
        },
        {
            Key: "Hold",
            Value: 5,
            DisplayName: "Hold",
        },
        {
            Key: "Settled",
            Value: 6,
            DisplayName: "Settled",
        },
    ];

    return array.filter(obj => values.includes(obj.Value));
}

export default GetObjectsByValues

export function GetObjectsByAccountGroups(...values) {
    const array = [
        {
            Key: "All Accounts",
            Value: 0,
            DisplayName: "All Accounts",
        },
        {
            Key: "Parties",
            Value: 1,
            DisplayName: "Parties",
        },
        {
            Key: "Sales",
            Value: 2,
            DisplayName: "Sales",
        },
        {
            Key: "Rent",
            Value: 3,
            DisplayName: "Rent",
        },
        {
            Key: "Vendors",
            Value: 4,
            DisplayName: "Vendors",
        },
        {
            Key: "Purchase",
            Value: 5,
            DisplayName: "Purchase",
        },
        {
            Key: "Income",
            Value: 6,
            DisplayName: "Income",
        },
        {
            Key: "Expenses",
            Value: 7,
            DisplayName: "Expenses",
        },
    ];

    return array.filter(obj => values.includes(obj.Value));
}

export function GetObjectsByAccessoryiesValues(...values) {
    const array = [
        {
            AccessoryId: 1,
            Id: 1,
            Name: "Red",
            Stock: 25,
        },
        {
            AccessoryId: 1,
            Id: 2,
            Name: "Yellow",
            Stock: 10,
        },
        {
            AccessoryId: 1,
            Id: 3,
            Name: "Green",
            Stock: 5,
        },
        {
            AccessoryId: 1,
            Id: 4,
            Name: "Blue",
            Stock: 6,
        },
        {
            AccessoryId: 2,
            Id: 1,
            Name: "Purple",
            Stock: 45,
        },
        {
            AccessoryId: 2,
            Id: 2,
            Name: "White",
            Stock: 56,
        },
        {
            AccessoryId: 2,
            Id: 3,
            Name: "Cream",
            Stock: 30,
        },
        {
            AccessoryId: 3,
            Id: 1,
            Name: "Black",
            Stock: 2,
        },
        {
            AccessoryId: 3,
            Id: 2,
            Name: "Brown",
            Stock: 5,
        },
        {
            AccessoryId: 3,
            Id: 3,
            Name: "Dark Blue",
            Stock: 6,
        },
        {
            AccessoryId: 3,
            Id: 4,
            Name: "Golden",
            Stock: 42,
        },
        {
            AccessoryId: 3,
            Id: 5,
            Name: "Silver",
            Stock: 12,
        },
        {
            AccessoryId: 4,
            Id: 1,
            Name: "Sky",
            Stock: 35,
        },
        {
            AccessoryId: 4,
            Id: 2,
            Name: "B-35",
            Stock: 20,
        },
        {
            AccessoryId: 4,
            Id: 3,
            Name: "S-45",
            Stock: 6,
        },
        {
            AccessoryId: 4,
            Id: 4,
            Name: "P-2",
            Stock: 8,
        },
        {
            AccessoryId: 4,
            Id: 5,
            Name: "M-00",
            Stock: 10,
        },
        {
            AccessoryId: 4,
            Id: 6,
            Name: "Diamond",
            Stock: 11,
        },
        {
            AccessoryId: 4,
            Id: 7,
            Name: "Silver",
            Stock: 15,
        },
        {
            AccessoryId: 5,
            Id: 1,
            Name: "Golden",
            Stock: 26,
        },
        {
            AccessoryId: 5,
            Id: 2,
            Name: "Cream",
            Stock: 14,
        },
        {
            AccessoryId: 5,
            Id: 3,
            Name: "Red-4",
            Stock: 3,
        },
        {
            AccessoryId: 5,
            Id: 4,
            Name: "White-3",
            Stock: 16,
        },
        {
            AccessoryId: 5,
            Id: 5,
            Name: "Brown",
            Stock: 47,
        },
        {
            AccessoryId: 5,
            Id: 6,
            Name: "Pink",
            Stock: 15,
        },
    ];

    return array.filter(obj => values.includes(obj.AccessoryId));
}
