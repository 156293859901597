import { routes } from "routes/path"

export const routesRoles = {
  [routes.index]: {
    role: ["Admin", "Vendor", "Salesman", ""],
  },
  [routes.dashboard]: {
    role: ["Admin", "Vendor", ""],
  },
  [routes.tutorial]: {
    role: ["Admin"],
  },
  [routes.tutorialForVendor]: {
    role: ["Admin", "Vendor", "Salesman"],
  },
  [routes.userList]: {
    role: ["Admin", "Vendor"],
  },
  [routes.userCreate]: {
    role: ["Admin", "Vendor"],
  },
  [routes.userUpdate]: {
    role: ["Admin", "Vendor"],
  },
  [routes.inquiries]: {
    role: ["Admin"],
  },
  [routes.categories]: {
    role: ["Admin", "Vendor"],
  },
  [routes.accessory]: {
    role: ["Admin", "Vendor"],
  },
  [routes.size]: {
    role: ["Admin", "Vendor"],
  },
  [routes.color]: {
    role: ["Admin", "Vendor"],
  },
  [routes.timeslot]: {
    role: ["Admin", "Vendor"],
  },
  [routes.reminder]: {
    role: ["Admin", "Vendor"],
  },
  [routes.accounts]: {
    role: ["Admin", "Vendor"],
  },
  [routes.items]: {
    role: ["Admin", "Vendor"],
  },
  [routes.plan]: {
    role: ["Admin"],
  },
  [routes.available]: {
    role: ["Admin", "Vendor", "Salesman"],
  },
  [routes.demo]: {
    role: ["Admin", "Vendor"],
  },
  [routes.productList]: {
    role: ["Admin", "Vendor"],
  },
  [routes.catalogue]: {
    role: ["Admin", "Vendor", "Salesman"],
  },
  [routes.productAvailable]: {
    role: ["Admin", "Vendor", "Salesman"],
  },
  [routes.groomWellProfile]: {
    role: ["Admin", "Vendor"],
  },
  [routes.mapCategory]: {
    role: ["Admin", "Vendor"],
  },
  [routes.exportProduct]: {
    role: ["Admin", "Vendor"],
  },
  [routes.groomWellProduct]: {
    role: ["Admin", "Vendor"],
  },
  [routes.createProduct]: {
    role: ["Admin", "Vendor"],
  },
  [routes.updateproduct]: {
    role: ["Admin", "Vendor"],
  },
  [routes.bookingList]: {
    role: ["Admin", "Vendor"],
  },
  [routes.createbooking]: {
    role: ["Admin", "Vendor"],
  },
  [routes.updatebooking]: {
    role: ["Admin", "Vendor"],
  },
  [routes.cartlist]: {
    role: ["Admin", "Vendor"],
  },
  [routes.reportBooking]: {
    role: ["Admin", "Vendor"],
  },
  [routes.delivery]: {
    role: ["Admin", "Vendor"],
  },
  [routes.return]: {
    role: ["Admin", "Vendor"],
  },
  [routes.deposit]: {
    role: ["Admin", "Vendor"],
  },
  [routes.accessoryReport]: {
    role: ["Admin", "Vendor"],
  },
  [routes.pendingBills]: {
    role: ["Admin", "Vendor"],
  },
  [routes.salesman]: {
    role: ["Admin", "Vendor"],
  },
  [routes.BookedOrderReport]: {
    role: ["Admin", "Vendor"],
  },
  [routes.feedback]: {
    role: ["Admin"],
  },
  [routes.finance]: {
    role: ["Admin"],
  },
  [routes.setting]: {
    role: ["Admin", "Vendor"],
  },
  [routes.invoice]: {
    role: ["Admin", "Vendor"],
  },
  [routes.subscription]: {
    role: ["Admin"],
  },
  [routes.decrypt]: {
    role: ["Admin"],
  },
  [routes.profile]: {
    role: ["Admin", "Vendor", "Salesman"],
  },
  [routes.viewBooking]: {
    role: ["Admin", "Vendor", "Salesman"],
  },
  [routes.importProduct]: {
    role: ["Admin", "Vendor"],
  },
  [routes.productHistory]: {
    role: ["Admin", "Vendor"],
  },
  [routes.HoldBillList]: {
    role: ["Admin", "Vendor"],
  },
  [routes.income]: {
    role: ["Admin", "Vendor"],
  },
  [routes.expenses]: {
    role: ["Admin", "Vendor"],
  },
  // [routes.journalVouchers]: {
  //   role: ["Admin", "Vendor"],
  // },
  [routes.incomeExpense]: {
    role: ["Admin", "Vendor"],
  },
  [routes.accountLedger]: {
    role: ["Admin", "Vendor"],
  },
  [routes.createSale]: {
    role: ["Admin", "Vendor"],
  },
  [routes.updateSale]: {
    role: ["Admin", "Vendor"],
  },
  [routes.salesList]: {
    role: ["Admin", "Vendor"],
  },
  [routes.purchaseList]: {
    role: ["Admin", "Vendor"],
  },
  [routes.createPurchase]: {
    role: ["Admin", "Vendor"],
  },
  [routes.UpdatePurchase]: {
    role: ["Admin", "Vendor"],
  },
  [routes.releseNote]: {
    role: ["Admin", "Vendor"],
  },
  [routes.stockRegister]: {
    role: ["Admin", "Vendor"],
  },
  [routes.dbBackup]: {
    role: ["Admin"],
  },
  [routes.salesGSTReport]: {
    role: ["Admin", "Vendor"],
  },
  [routes.washing]: {
    role: ["Admin", "Vendor"],
  },
  // [routes.CreditNote]: {
  //   role: ["Admin", "Vendor"],
  // },
  // [routes.CreateCreditNote]: {
  //   role: ["Admin", "Vendor"],
  // },
}
