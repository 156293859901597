import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
} from "reactstrap"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation, useNavigate } from "react-router-dom"
import useApi from "common/ApiMiddlewere"
import GetObjectsByValues from "_mock/Static_data"
import CreditNotesTable from "./CreditNotesTable"
import { routes } from "routes/path"
import { exportDataExcel } from "pages/ExportData"


function CreditNotes() {
    document.title = `CreditNotes | ${localStorage.getItem("ShopName")}`
    const { postApi, getApi } = useApi()
    let { pathname } = useLocation()
    const navigate = useNavigate()
    GetObjectsByValues(1, 2, 3, 4, 5)
    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [TotalRecords, setTotalRecords] = useState(0.0)
    const [dateDisble, setdateDisble] = useState(false)
    const [date, setdate] = useState([new Date(), new Date()])
    const [pageSize, setpageSize] = useState(20)
    const [displayStart, setDisplayStart] = useState(1)
    const [searchText, setSearchText] = useState("")

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setpageSize(pageSize)
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
    }

    const creditNotesApi = () => {
        setIsLoading(true)
        postApi("CreditNote/list", {
            fromDate: !dateDisble
                ? null
                : date[0]
                    ? dateFormat(date[0], "yyyy-MM-dd")
                    : new Date().toISOString().slice(0, 10),
            toDate: !dateDisble
                ? null
                : date[1]
                    ? dateFormat(date[1], "yyyy-MM-dd")
                    : new Date().toISOString().slice(0, 10),
            displayStart: displayStart,
            pageSize: pageSize,
            searchText: searchText,
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data?.CreditNoteList)
                // setTableData(data?.Saleslist)
                setTotalRecords(data?.TotalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    // booking list API useEffect
    useEffect(() => {
        if (date[1] != undefined) {
            creditNotesApi()
        }
    }, [
        date,
        searchText,
        displayStart,
        dateDisble,
        pageSize,
    ])

    const handleCreateCreditNote = () => {
        navigate(routes.CreateCreditNote)
    }

    const handleDownload = async (id, Billno) => {
        setIsLoading(true)
        getApi(`Report/credit-note-print?creditNoteId=${id}`).then(({ data, status }) => {
            const byteCharacters = atob(data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `invoice_${Billno}.pdf`;
            link.click();
            window.URL.revokeObjectURL(link.href);
            setIsLoading(false)
        })
    };

    const handlePrint = async (id) => {
        setIsLoading(true)
        getApi(`Report/credit-note-print?creditNoteId=${id}`).then(({ data, status }) => {
            const byteCharacters = atob(data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url);

            setIsLoading(false)
            newWindow.addEventListener('load', () => {
                newWindow.print();
            });
        })
    };

    function writeDataToExcel() {
        setIsLoading(true)
        let header = ["Note No.", "Bill No.", "Name", "Customer No.", "Date", "Rent", "GST Amt.",];
        const mainTitle = "Credit Notes";
        const widthObj = [{
            Key: "A",
            Value: 12,
        },
        {
            Key: "B",
            Value: 12,
        },
        {
            Key: "C",
            Value: 25,
        },
        {
            Key: "D",
            Value: 13,
        },
        {
            Key: "E",
            Value: 10,
        },
        {
            Key: "F",
            Value: 10,
        },
        {
            Key: "G",
            Value: 12,
        },
        ]
        const extention = "Credit_Notes.xlsx"
        postApi("CreditNote/list", {
            fromDate: !dateDisble
                ? null
                : date[0]
                    ? dateFormat(date[0], "yyyy-MM-dd")
                    : new Date().toISOString().slice(0, 10),
            toDate: !dateDisble
                ? null
                : date[1]
                    ? dateFormat(date[1], "yyyy-MM-dd")
                    : new Date().toISOString().slice(0, 10),
            displayStart: 1,
            pageSize: 10000,
            searchText: searchText,
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTotalRecords(data.TotalRecords)
                let excelSheetData = []
                for (let index = 0; index < data.CreditNoteList.length; index++) {
                    let dataObj = {
                        NoteNo: data.CreditNoteList[index].NoteNo,
                        BillNo: data.CreditNoteList[index].BillNo,
                        Name: data.CreditNoteList[index].Name,
                        ContactNo: data.CreditNoteList[index].ContactNo,
                        Date: dateFormat(data.CreditNoteList[index].Date.split("T")[0],
                            "dd/MM/yyyy"),
                        NetAmount: data.CreditNoteList[index]?.NetAmount?.toFixed(2),
                        GSTAmount: data.CreditNoteList[index]?.GSTAmount?.toFixed(2),
                    }
                    excelSheetData.push(dataObj)
                }
                var fields = Object.keys(excelSheetData[0]);
                var sheetData = excelSheetData.map(function (row) {
                    return fields.map(function (fieldName) {
                        return row[fieldName];
                    });
                });
                sheetData.unshift(header);
                exportDataExcel(sheetData, mainTitle, widthObj, extention, 0, 0, 0, 0, 0, 0)

            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    // Booking table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Note No.",
                accessor: "NoteNo",
                width: "5%",
            },
            {
                Header: "Bill No.",
                accessor: "BillNo",
                width: "5%",
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Customer No.",
                accessor: "ContactNo",
                width: "6%",
            },
            {
                Header: "Date",
                accessor: "Date",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.Date == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.Date.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Rent
                    </div>
                ),
                accessor: "NetAmount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(Math.round(row?.original?.NetAmount) || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        GST Amt.
                    </div>
                ),
                accessor: "GSTAmount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.GSTAmount || 0)}
                        </div>
                    )
                },
            },
            // {
            //     Header: "Remarks",
            //     accessor: "Remarks",
            //     width: "7%",
            //     Cell: ({ row }) => (
            //         <p
            //             className="mb-0"
            //             data-toggle="tooltip"
            //             data-placement="bottom"
            //             title={`${row.original?.Remarks == null ? '' : row.original?.Remarks}`}
            //         >
            //             {row.original.Remarks?.length > 15
            //                 ? `${row.original?.Remarks.substr(0, 15)}...`
            //                 : row.original?.Remarks == ""
            //                     ? "N/A"
            //                     : row.original?.Remarks}
            //         </p>
            //     ),
            // },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "8%",
                accessor: "Action",
                disableFilters: true,
                Cell: ({ row }) => {
                    const renderActionButton = (iconClass, onClickHandler, text) => (
                        <li className="mytooltip">
                            <i
                                className={`fs-6 fas ${iconClass}`}
                                style={{ color: iconClass === "fa-print" ? "#f39900" : "#90499c", cursor: "pointer" }}
                                onClick={onClickHandler}
                            ></i>
                            <span className="mytext">{text}</span>
                        </li>
                    );

                    return (
                        <>
                            {
                                (
                                    <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                                        <>
                                            {renderActionButton("fa-print", () => handlePrint(row.original.Id), "Print")}
                                            {renderActionButton("fa-download", () => handleDownload(row.original.Id, row.original.BillNo), "Download")}
                                        </>
                                    </ul >
                                )
                            }
                        </>
                    )
                },
            },

        ],
        [tableData]
    )

    return (
        <div className="page-content background">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <CreditNotesTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            handleUserClick={handleCreateCreditNote}
                            customPageSize={20}
                            isLoading={isLoading}
                            pagePerSize={pagePerSize}
                            TotalRecords={TotalRecords}
                            className="custom-header-css"
                            dateDisble={dateDisble}
                            setdateDisble={setdateDisble}
                            date={date}
                            setdate={setdate}
                            writeDataToExcel={writeDataToExcel}

                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
CreditNotes.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default CreditNotes